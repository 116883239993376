form {
    padding: 22px 16px;
  }

  form h2 {
    font-size: 12px;
    font-weight: 700;
  }

  form .form-group {
    margin-bottom: 21px;
  }

  form .form-control {
    padding: 7px 12px;
    height: 42px;
    border: 1px solid #e8e8e8;
    font-size: 14px;
    font-weight: 400;
    border-radius: 0px;
  }

  textarea:focus,
  input[type="email"]:focus,
  input[type="password"]:focus,
  input[type="fname"]:focus,
  input[type="lname"]:focus,
  input[type="email"]:focus,
  input[type="mobile"]:focus,
  input[type="password"]:focus,
  input[type="cpassword"]:focus,
  .uneditable-input:focus {
    border-color: rgb(50, 44, 42);
  }

  .focusedInput:focus {
    border-color: #222222;
    outline: 0;
    -moz-box-shadow: 0 0 2px 2px #cacaca;
    box-shadow: 0 0 2px 2px #cacaca !important;
  }

  .custom-check-wrap {
    float: right;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .customFormReset {
    margin: 6px 0 0;
    padding: 0;
    position: absolute;
  }

  .fake-label {
    position: relative;
    padding-left: 19px;
    padding-top: 10px;
    font-size: 12px;
    font-weight: 400;
  }

  .custom-check-wrap .fake-label:before {
    border: 1px solid #aaa;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    top: 10px;
    width: 13px;
    height: 13px;
  }

  .custom-check-wrap .fake-label:before {
    position: absolute;
    content: "";
    bottom: auto;
    left: 0;
    right: auto;
  }

  .custom-check-wrap .fake-label:after {
    border: solid #28ca6c;
    border-width: 0 0 2px 2px;
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    display: none;
    left: 3px;
    top: 14px;
    width: 7px;
    height: 4px;
  }

  .custom-check-wrap .fake-label:after {
    position: absolute;
    content: "";
    bottom: auto;
    right: auto;
  }

  .custom-check-wrap input:checked+.fake-label:after {
    display: block;
  }

  @media (min-width: 992px) {
    form h2 {
      font-size: 20px;
    }
  }

  @media (min-width: 1200px) {
    form {
      padding: 42px 46px;
    }

    form .form-control {
      height: 52px;
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    form {
      padding: 32px 26px;
    }
  }