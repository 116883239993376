/*------------------------------------------------------------------
1. Import font and weight from google fonts
2. Embedded fonts styles
3. Arabic RTL Changes

-----------------------------------------------------------------*/
/*------------------------------------------------------------------
1. import files / import files
-------------------------------------------------------------------*/

.cont-pad {
  font-family: Lato;
}

.box {
  width: 500px;
  padding: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  margin: auto;
}



@media (min-width: 1400px) {
  .cont-pad {
    max-width: 1170px !important;
  }
}

@media (max-width: 1400px) {
  .cont-pad {
    max-width: 1170px !important;
  }
}

@media (max-width: 1200px) {
  .cont-pad {
    max-width: 97.5% !important;
  }
}

@media (max-width: 1025px) {
  .cont-pad {
    max-width: 95% !important;
  }
}

@media (max-width: 990px) {
  .cont-pad {
    max-width: 750px !important;
  }
}

@media (max-width: 576px) {
  .cont-pad {
    max-width: 100% !important;
  }
}

.cont-pad-1 {
  font-family: Lato;
}

@media (min-width: 1400px) {
  .cont-pad-1 {
    max-width: 1170px !important;
  }
}

@media (max-width: 1200px) {
  .cont-pad-1 {
    max-width: 97.5% !important;
  }
}

@media (max-width: 1025px) {
  .cont-pad-1 {
    max-width: 99% !important;
  }
}

@media (max-width: 990px) {
  .cont-pad-1 {
    max-width: 750px !important;
  }
}

@media (max-width: 768px) {
  .cont-pad-1 {
    max-width: 95% !important;
    padding: 10px 0px;
  }
}

@media (max-width: 576px) {
  .cont-pad-1 {
    max-width: 95% !important;
  }
}

.nav-pad {
  height: 47px;
  padding: 0px;
  background-color: #222222;
}

@media (max-width: 1200px) {
  .nav-pad {
    height: 40px;
    margin: 0px;
    padding: 0px;
  }
}

.nav-pad-1 {
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 8px;
  font-family: Lato;
  font-weight: 700;
  color: #e8e8e8;
  line-height: 16px;
  font-size: 12px;
  letter-spacing: 0.5px;
}

@media (max-width: 1200px) {
  .nav-pad-1 {
    padding: 8px;
  }
}

.tog-but {
  border: 1px solid black !important;
  padding: 0px 5px !important;
  border-radius: 0px !important;
}

.navbar {
  padding: 0px !important;
}

.navbar-collapse {
  color: black !important;
}

.dropdown-item {
  margin-bottom: 12px;
}

@media (max-width: 991px) {
  .nav-1 {
    background-color: #ffffff;
    color: #000000 !important;
  }
}

.d-m {
  width: 250px;
}

@media (max-width: 600px) {
  .d-m {
    width: auto;
  }
}

.nav-1 {
  margin-top: 56px;
}

@media (max-width: 1025px) {
  .nav-1 {
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .nav-1 {
    margin-top: 40px;
  }
}

.navbutton:hover {
  box-shadow: 0 0 2px 2px #a3a3a3 inset !important;
}

.navbutton {
  font-size: 12px;
  border-left: 1px solid #858585;
  border-top: 0px;
  color: white;
  background-color: #222222;
}

@media (max-width: 768px) {
  .navbutton {
    height: 35px;
  }
}

.anchor {
  color: white;
  font-weight: bold;
}

.anchor:hover {
  color: #1d96a7;
}

.anchor1 {
  color: #525252;
  font-weight: bold;
}

.anchor1:hover {
  color: #ffffff;
}

@media (min-width: 610px) {
  span.icon {
    display: none;
  }

  span.text {
    display: inline-block;
  }
}

@media (max-width: 611px) {
  span.icon {
    display: inline-block;
  }

  span.text {
    display: none;
  }
}

@media (min-width: 992px) {
  span.icon-1 {
    display: none;
  }

  span.text-1 {
    display: inline-block;
  }
}

@media (max-width: 991px) {
  span.icon-1 {
    display: inline-block;
  }

  span.text-1 {
    display: none;
  }
}

.navcol {
  padding-left: 13px !important;
  padding-right: 13px !important;
  color: #ffffff !important;
}

.navcol:hover {
  color: #34c7dc !important;
  transition: 0.3s;
}

@media (max-width: 991px) {
  .navcol {
    color: #000000 !important;
  }
}

.nav-2 {
  background-color: #222222;
}
@media (max-width: 1025px) {
  .nav-2 {
    display: none !important;
  }
}

.navimg {
  max-width: 200px;
}

@media (max-width: 768px) {
  .navimg {
    max-width: 150px;
  }
}

.logo {
  padding: 20px 0px;
}

@media (max-width: 768px) {
  .logo {
    padding: 0px 0px;
  }
}

.body {
  font-family: "Lato", sans-serif;
}

@media (max-width: 768px) {
  #navpad {
    padding-left: 0px;
  }
}

@media (min-width: 768px) {
  #navpad {
    padding-left: 0px;
  }
}

.lang-button {
  border: none;
  background-color: rgb(34, 34, 34);
  font-size: 12px;
  padding: 2px !important;
}

.en-lang-ar {
  margin-right: 2px solid #777777;

}

.sep {
  color: #777777;
}

.logreg {
  display: inline;
  padding-left: 5px;
}


/*------------------------------------------------------------------
3. Arabic Changes
-------------------------------------------------------------------*/

.rtl {
  flex-direction: row-reverse;
}

.ltr {
  flex-direction: row;
}



@media (min-width: 990px) {
  .rtl ul {
    flex-direction: row-reverse !important;
  }

  .ltr ul {
    flex-direction: row !important;
  }
}

.dropdown .dropdown-menu a {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
}

.dropdown .dropdown-menu {
  padding: 0px;
}

.dropdown .dropdown-menu a:hover {
  color: #ffffff;
  background-color: #1EABBA;
  border-color: #1EABBA;
  transition: 0.2s;
}
.top-ribbon-mobile-buttons  {
  display: none;
}

@media (max-width: 768px) {
  .top-ribbon-desktop-buttons {
    display: none
  }
  .top-ribbon-mobile-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
