#back-top{
    position: fixed;
    right: 20px;
    bottom: 20px;
    font-size: 25px;
    line-height: 0.53333;
    color: #fff;
    cursor: pointer;
    float: right;
    width: 50px;
    height: 50px;
    padding: 16px 0;
    z-index: 9;
    opacity: 1;
    visibility: visible;
    background: #1eabba;
    border-radius: 5px;
    -webkit-transition: all 0.6s ease 0s;
    -o-transition: all 0.6s ease 0s;
    transition: all 0.6s ease 0s;
  }
  
  
  #back-top:hover {
    opacity: 0.7;
  }
  
  .scrollTop {
    position: fixed; 
    width: 100%;
    bottom: 20px;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
  }
  
  .scrollTop:hover{
    opacity: 1;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }