.courseBanner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/banner_course.jpg");
    background-size: cover;
    background-position: center;
    height: 400px;
}

.breadcrumb {
    background-color: #ffffff;
}

.icon {
    color: #B1B1B1;
}

.icon:hover {
    color: #34C7DC;
}

.card-img-top{
    border-radius: 0px;
}

.card-hov{
    border-radius: 0px;
}

.card-hov:hover {
    border-color: #34c7dc;
}

.course-anchor {
    color: #777777 !important;
}

.course-anchor:hover {
    color: #34c7DC !important;
}

.course-anchor3 {
    color: #777777 !important;
}

.course-anchor3:hover {
    color: #222222 !important;
}

@media (min-width: 1200px){
    .cnt-width{
       max-width: 1170px;
   }
}

.t-1{
    font-size: 15px;
    font-weight: 600;
    line-height: 1px;
}

.pa-1{
    font-size: 14px;
    font-weight: 400;
    font-family: Lato;
    color: #777777;
    line-height: 17px;
}

.overlayslide {
    position: relative;
    width: 100%;
  }
  
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top:0;
    background-color: #1EABBA;
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: 0.5s ease;
  }
  
  .overlayslide:hover .overlay {
    top: 0;
    width: 100%;
    opacity: 0.6;
  }

  .overlayslide2 {
    position: relative;
    width: fit-content;
  }
  
  .overlay2 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top:0;
    background-color: #1EABBA;
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: 0.5s ease;
  }
  
  .overlayslide2:hover .overlay2 {
    top: 0;
    width: 100%;
    opacity: 0.6;
  }


  .overlayslide1 {
    position: relative;
    width: 100%;
  }
  
  .overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #1EABBA;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.5s ease;
  }
  
  .overlayslide1:hover .overlay1 {
    top: 0;
    Height: 100%;
    opacity: 0.6;
  }

  .but-drop{
    border-radius: 0px;
    border-color: #cccccc;
}

.course-anchor1 {
    color: #222222 !important;
}

.course-anchor1:hover {
    color: #1EABBA !important;
}

.course-anchor2 {
    color: #777777 !important;
    font-size: 14px;
}

.course-anchor2:hover {
    color: #1EABBA !important;
}

.but-grp{
    background-color: #ffffff;
    border-color: #cccccc;
    color: #222222;
    border-radius: 0px;
}

.but-grp:hover{
    background-color: #1EABBA;
    border-color: #1EABBA;
    color: #ffffff;
}

.but-grp1{
    background-color: #1EABBA;
    border-color: #1EABBA;
    color: #ffffff;
    border-radius: 0px;
}

.but-grp1:hover{
    color: #ffffff;
}

@media (max-width: 768px){
    .c-pad{
    padding-right: 0px;
    padding-left: 0px;
}
}
