.body {
	font-family: 'Lato', sans-serif;
}

.fa {
	padding: 20px;
	font-size: 12px;
	text-align: center;
	margin: 5px 10px;
}

.footer-social-media-links{
	float: right;
}

@media screen and (max-width: 760px) {
	.footer-social-media-links{
		float: none;
		text-align: center;
	}
}

.fa:hover {
	opacity: 0.9;
}

.fa-facebook {
	color: rgb(146, 145, 145);
}

.fa-twitter {
	color: rgb(255, 255, 255);
}

.fa-google {
	color: rgb(146, 145, 145);
}

.fa-instagram {
	color: rgb(146, 145, 145);
}

.lang {
	padding: 5px 15px;
	text-decoration: none !important;
}

#hr-3 {
	width: 100%;
	border-top: 1px solid #919090;
}

.footer {
	background-image: linear-gradient(to right, #222222, #222222);
	padding-top: 50px;
}

.b-1 {
	border-radius: 4px;
	background-color: none;
	border-color: #f2547d;
	color: #f2547d;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	width: 200px;
	padding: 10px;
	transition: all 0.5s;
	cursor: pointer;
}

.b-1 {
	cursor: pointer;
	display: inline-block;
	position: relative;
	transition: 0.5s;
}

.b-1:hover {
	padding-right: 24px;
	padding-left: 8px;
	transition: 0.5s;
	color: #ffffff;
}

.b-1:after {
	content: "»";
	position: absolute;
	opacity: 0;
	right: -20px;
	transition: 0.5s;
	color: #ffffff;
}

.b-1:hover:after {
	opacity: 1;
	right: 15px;
	transition: 0.5s;
}

a {
	text-decoration: none !important;
}

.footer-icn {
	font-size: 20px;
	padding: 0px 3px;
	color: grey;
}

.footer-icn:hover {
	color: #34d7ec;
}

.fa-twitter {
	color: grey;
}

.fa-twitter:hover {
	color: #34d7ec;
}

.fa-instagram {
	color: grey;
}

.fa-instagram:hover {
	color: #34d7ec;
}

@media (max-Width: 600px) {
	.dis-no {
		display: none;
	}
}



.rtl {
	text-align: right;
	flex-direction: row-reverse;

}

.rtl ul li {
	direction: rtl;
}

.rtl p {
	text-align: right;
}

.ltr {
	flex-direction: row;

}